<template>
  <div>
    <el-dialog title="批量修改"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               label-width="100px"
               :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="模块:"
                          prop="target_type">
              <el-select v-model="form.target_type"
                         style="width:100%"
                         @change="addRule"
                         placeholder="请选择">
                <el-option v-for="item in targetTypeList"
                           :key="item.id"
                           :label="item.label"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="科目:"
                          prop="subject_id">
              <el-select v-model="form.subject_id"
                         style="width:100%"
                         @change="subjectChangeGetSelects"
                         placeholder="请选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="年级:"
                          prop="grade_id">
              <el-select v-model="form.grade_id"
                         multiple
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="考点:"
                          prop="knowledge_id">
              <div class="zhishidian">
                <el-cascader v-model="form.knowledge_id"
                             style="width:100%"
                             :options="selects.knowledge"
                             :props="knowledgeProps"></el-cascader>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="教材:">
              <el-select v-model="form.textbook_id"
                         style="width:100%"
                         clearable
                         filterable
                         placeholder="请选择">
                <el-option v-for="item in textbookList"
                           :key="item.textbook_id"
                           :label="item.textbook_name"
                           :value="item.textbook_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="章节:">
              <div class="zhishidian">
                <el-cascader v-model="form.chapter_id"
                             style="width:100%"
                             :options="selects.chapter"
                             :props="chapterProps"></el-cascader>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="题型:">
              <el-select v-model="form.question_type_id"
                         multiple
                         filterable
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in selects.questionType"
                           :key="item.question_type_id"
                           :label="item.question_type_name"
                           :value="item.question_type_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="createSource">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSelectsBySubject, batchEdit } from '@/api/uploadResource.js'
import { getUserSubject } from '@/api/subject.js'
import { getGradeBysubject } from '@/api/grade.js'
import { getTextBookBySubject } from '@/api/textBook.js'
export default {
  data () {
    return {
      show: false,
      form: {},
      rules: {
        subject_id: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        target_type: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        grade_id: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
      },
      textBook: [],
      subjectList: [],
      gradeList: [],
      selects: {
        knowledge: [],
        textbook: [],
        chapter: [],
        questionType: [],
      },
      knowledgeProps: {
        multiple: true,
        children: 'child',
        value: 'knowledge_id',
        label: 'knowledge_desc',
      },
      chapterProps: {
        multiple: true,
        children: 'child',
        value: 'chapter_id',
        label: 'chapter_desc',
      },
      dialogVisible: false,
      targetTypeList: [
        { id: 1, label: '专题讲解' },
        { id: 2, label: '考点讲解' },
      ],
      textbookList: []
    }
  },

  methods: {
    addRule () {
      var rules = JSON.parse(JSON.stringify(this.rules))

      if (this.form.target_type == 1) {
        rules['knowledge_id'] = [
          { required: false, message: '请选择', trigger: 'change' }
        ]
      } else {
        rules['knowledge_id'] = [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      }
      this.$set(this, 'rules', rules)
      console.log(rules)
      this.$forceUpdate()
    },
    upload () {
      this.fileList = []
      this.$get_file({ fileType: 'video/*' }).then(res => {
        this.fileList.push(res)
        this.form.filename = res.name.substring(0, res.name.indexOf("."))
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    resetForm () {
      this.form.grade_id = []
      this.form.knowledge_id = []
      this.form.chapter_id = []
      this.form.question_type_id = []
      this.form.textbook_id = ''
      this.selects = {
        knowledge: [],
        textbook: [],
        chapter: [],
        questionType: [],
      }
    },
    async getTextBook () {
      this.form.textbook_id = ''
      this.textBookList = []
      if (!this.form.subject_id) return
      let params = {
        subject_id: this.form.subject_id
      }
      const { data } = await getTextBookBySubject(params)
      this.textbookList = data
    },
    async subjectChangeGetSelects () {
      this.getTextBook()
      this.resetForm()
      if (!this.form.subject_id) {
        return
      }
      this.getGradeList()
      // 获取下拉框参数
      let params = {
        subject_id: this.form.subject_id
      }
      const { data } = await getSelectsBySubject(params)
      this.selects = data
    },
    getList () {
      this.getSubjectList()
    },
    async getSubjectList () {
      const { data } = await getUserSubject()
      this.subjectList = data.list
    },
    async getGradeList () {
      this.form.grade_id = []
      const { data } = await getGradeBysubject({ subject_id: this.form.subject_id })
      this.gradeList = Object.values(data)
    },

    createSource () {
      this.$refs.formRef.validate(async val => {
        if (!val) return

        let form = JSON.parse(JSON.stringify(this.form))
        form.knowledge_id = form.knowledge_id.length ? [...new Set(form.knowledge_id.join(',').split(','))] : []
        form.chapter_id = form.chapter_id.join(',').length ? [...new Set(form.chapter_id.join(',').split(','))] : []

        // 调接口
        await batchEdit(form)
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        this.handleClose()
        this.$parent.search()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
::v-deep .dialog-footer {
  text-align: center;
}
.addFile {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  line-height: 146px;
  vertical-align: top;
  text-align: center;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

.zhishidian {
  height: 33px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  border-radius: 4px;
  ::v-deep .el-cascader__tags .el-tag:not(.is-hit) {
    height: 33px;
  }
  ::v-deep .el-cascader__tags .el-tag {
    background-color: #ffffff;
  }
  ::v-deep .el-cascader__tags {
    height: 33px;
  }
  ::v-deep .el-icon-close {
    display: none;
  }
}

::v-deep .el-dialog {
  margin-top: 10vh !important;
}
</style>